import React from 'react';
import { BackgroundImage, Box } from '@mantine/core';
import { IconCheckbox } from '@tabler/icons-react';

type AvatarEquipmentProps = {
  active: boolean;
  image: string;
  onClick?: () => void;
};

export function AvatarEquipment(props: React.PropsWithChildren<AvatarEquipmentProps>) {
  return (
    <BackgroundImage
      src={props.image}
      radius="md"
      w={75}
      h={75}
      sx={{position: 'relative'}}
      onClick={props.onClick && props.onClick}
    >
     {props.active ? (
      <IconCheckbox style={{position: 'absolute', right: 5, bottom: 5}} color="#00bb00" />
     ): (
      <Box
        w={75}
        h={75}
        opacity={0.8}
        sx={(theme) => ({
          borderRadius: theme.radius.md,
          backgroundColor: theme.colorScheme === 'light' ? '#aaaaaa' : '#000000',
        })}
      />
     )} 
    </BackgroundImage>
  );
}
