import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Alert,
  Notification,
} from '@mantine/core';
import { useForm, isEmail, hasLength } from '@mantine/form';
import { IconAlertCircle, IconUser, IconCheck } from '@tabler/icons-react';
import { useState } from 'react';
import { useAppService } from '../services/AppService';
import './Register.css';

enum RegisterStatus {
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface RegisterState {
  status: RegisterStatus;
  error?: string;
}

export function Register() {
  const appService = useAppService();
  const [registerState, setRegisterState] = useState<RegisterState>({status: RegisterStatus.READY});
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      username: hasLength({ min: 4, max: 16 }, 'Username must contain between 4 and 16 characters.'),
      email: isEmail('Invalid email'),
      password: hasLength({ min: 6 }, 'Password should contain at least 6 characters'),
      confirmPassword: (value, values) => value !== values.password ? 'Passwords do not match' : null,
    },
  });

  const onSubmit = async (values: any) => {
    console.log(values);
    setRegisterState({status: RegisterStatus.SUBMITTING});
    try {
      await appService.register(values.username, values.email, values.password);
      setRegisterState({status: RegisterStatus.SUCCESS});
    } catch (e: any) {
      console.error(e);
      setRegisterState({status: RegisterStatus.ERROR, error: e.message});
    }
  };

  return (
    <Container size={620} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Create Account
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Already have an account?{' '}
        <Anchor href="/login" size="sm">
          Sign in
        </Anchor>
      </Text>

      {registerState.status === RegisterStatus.SUCCESS ? (
        <Notification mt="xl" withCloseButton={false} icon={<IconCheck size="1.2rem" />} color="green" title="Account created!">
          Your account has been successfully created. You may{' '}
          <Anchor href="/login" size="sm">
            sign in
          </Anchor>
          {' '} now.
        </Notification>
      ) : (
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          {registerState.status === RegisterStatus.ERROR && (
            <Alert icon={<IconAlertCircle size="1rem" />} variant="outline" title="Create account failed" color="red" mb="1.5rem">
              {registerState.error}
            </Alert>
          )}
          <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <TextInput
              withAsterisk
              label="Username"
              placeholder="Enter username"
              {...form.getInputProps('username')}
            />
            <TextInput
              withAsterisk
              label="Email"
              placeholder="Enter email address"
              mt="md"
              {...form.getInputProps('email')}
            />
            <PasswordInput
              withAsterisk
              label="Password"
              placeholder="Enter your password"
              mt="md"
              {...form.getInputProps('password')}
            />
            <PasswordInput
              withAsterisk
              label="Confirm Password"
              placeholder="Enter your password"
              mt="md"
              {...form.getInputProps('confirmPassword')}
            />
            <Button type="submit" leftIcon={<IconUser size="1rem" />} disabled={!form.isValid()} loading={registerState.status === RegisterStatus.SUBMITTING} fullWidth mt="xl">
              {registerState.status === RegisterStatus.SUBMITTING ? 'Submitting...' : 'Create account'}
            </Button>
          </form>
        </Paper>
      )}
    </Container>
  );
}