import React, { useState } from 'react';
import { Menu, UnstyledButton, Stack, Button } from '@mantine/core';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { Session, SessionStatus } from '../types/Session';

type ConnectButtonProps = {
  sessions: Session[];
  onClick?: () => boolean,
  onSessionSelect?: (session: Session) => void;
  icon: React.ReactNode;
  label: string;
  mt?: number;
};

export function ConnectButton(props: React.PropsWithChildren<ConnectButtonProps>) {
  const [opened, setOpened] = useState(false);

  return (
    <Menu
      shadow="md"
      withArrow
      opened={opened}
      onChange={(open: boolean) => {
        if (!open && opened) {
          setOpened(false);
        }
      }}
    >
      <Menu.Target>
        <Button
          mt={props.mt ? props.mt : 0}
          onClick={() => {
            setOpened(!props.onClick || props.onClick());
          }}
          leftIcon={props.icon}
        >
          {props.label}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Sessions</Menu.Label>
        {props.sessions.map((session) => (
          <Stack>
            <UnstyledButton onClick={() => {
              if (session.status === SessionStatus.ACTIVE) {
                if (props.onSessionSelect) {
                  props.onSessionSelect(session);
                }
              }
            }}>
              <Menu.Item 
                icon={
                  session.status === SessionStatus.ACTIVE ? (
                    <IconCircleCheck size={14} color="#00ff00" />
                  ) : (
                    <IconCircleX size={14} color="#ff0000" />
                  )
                }
                disabled={session.status !== SessionStatus.ACTIVE}>
                Join {session.name}
              </Menu.Item>
            </UnstyledButton>
          </Stack>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
