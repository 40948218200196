import { Center, Loader, Stack, Text } from "@mantine/core";

export function Loading() {
  return (
    <Center style={{ width: '100vw', height: '100vh' }}>
      <Stack align="center">
        <Text>Loading app...</Text>
        <Loader variant="bars" />
      </Stack>
    </Center>
  );
}