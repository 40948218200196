import React from 'react';
import { API_BASE_URL } from '../Constants';
import { RootContext } from '../contexts/RootContext';
import { User } from '../types/User';
import { Server } from '../types/Server';
import { Session, SessionStatus } from '../types/Session';

const sessionData: Session[] = [
  {
    "id": 1,
    "name": "Session 1",
    "status": SessionStatus.ACTIVE,
    "url": "http://34.210.64.112:8000/"
  },
  {
    "id": 2,
    "name": "Session 2",
    "status": SessionStatus.ACTIVE,
    "url": "http://34.210.64.112:8001/"
  },
  {
    "id": 3,
    "name": "Session 3",
    "status": SessionStatus.ACTIVE,
    "url": "http://34.210.64.112:8002/"
  },
  {
    "id": 4,
    "name": "Session 4",
    "status": SessionStatus.INACTIVE,
    "url": "http://44.213.158.233:8003/"
  },
  {
    "id": 5,
    "name": "Session 5",
    "status": SessionStatus.INACTIVE,
    "url": "http://44.213.158.233:8004/"
  },
];

const SAFETY_GLASSES_ASSET = 'Glasses_1';
const HARD_HAT_ASSET = 'Helmet4';
const HEARING_PROTECTION_ASSET = 'PPE_HearingProtection_1';
const DEFAULT_VOICE = 'en';
const DEFAULT_INTF = 'en';

export interface LoginData {
  token: string;
  user: User;
}

export interface GamePreferenceData {
  characterId: number;
  safetyGlasses: boolean;
  hardHat: boolean;
  hearingProtection: boolean;
}

interface RawLanguageData {
  voice: string;
  intf: string;
}

interface RawPPEData {
  assetName: string;
  bActive: boolean;
  bEnabled: boolean;
}

interface RawGamePreferenceData {
  NickName: string;
  CharacterId: number;
  Ppe: RawPPEData[];
  Language: RawLanguageData;
}

const DEFAULT_RAW_PREFERENCE_DATA: RawGamePreferenceData = {
  NickName: '',
  CharacterId: -1,
  Ppe: [],
  Language: {
    voice: DEFAULT_VOICE,
    intf: DEFAULT_INTF,
  }
}

const DEFAULT_RAW_PPE_DATA: RawPPEData = {
    assetName: '',
    bActive: true,
    bEnabled: true,
}

export function useAppService() {
  const context = React.useContext(RootContext);

  async function login(username: string, password: string): Promise<LoginData> {
    let response = await fetch(`${API_BASE_URL}u/logins`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    if (!response.ok) {
      throw new Error('Login failed.');
    }
    let data = await response.json();
    return {
      token: data.token, 
      user: {id: data.user.id, email: data.user.email, roles: data.user.roles} as User,
    };
  }

  async function register(username: string, email: string, password: string) {
    console.log('registering user');
    let response = await fetch(`${API_BASE_URL}u/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        gender: 0,
        password: password,
        email: email,
        birthDate: '2001-01-01',
      }),
    });
    if (!response.ok) {
      if (response.status === 400) {
        throw new Error('The username specified is already in use.');
      } else if (response.status === 409) {
        throw new Error('The email specified is already in use.');
      } else {
        throw new Error('The registration could not be completed.');
      }
    }
  }

  async function updatePassword(password: string) {
    console.log('updating password');
    let response = await fetch(`${API_BASE_URL}u/users/${context?.state.user?.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context?.state.token,
      },
      body: JSON.stringify({
        password: password,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      if (!checkAuthorization(response)) {
        throw new Error('Unauthorized fetch.');
      } else {
        throw new Error('Change password failed.');
      }
    }
  }

  async function checkToken(token: string): Promise<boolean> {
    console.log('checking token');
    let response = await fetch(`${API_BASE_URL}u/logins/token/${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
    if (!response.ok) {
      return false;
    }
    let data = await response.json();
    return data.valid;
  }

  async function getListenServers(): Promise<Server[]> {
    console.log('getting listen servers');
    let response = await fetch(`${API_BASE_URL}r/servers`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + context?.state.token,
      },
    });
    if (!response.ok) {
      if (!checkAuthorization(response)) {
        throw new Error('Unauthorized fetch.');
      } else {
        throw new Error("Can't get listen servers.");
      }
    }
    let datas = await response.json();
    console.log('datas = ' + JSON.stringify(datas));
    let servers: Server[] = [];
    for (let i = 0; i < datas.length; i++) {
      const data = datas[i];
      let server: Server = {
        id: data.id,
        name: data.name,
        address: data.address,
        port: data.port,
        status: data.status,
      };
      servers.push(server);
    }
    console.log('servers = ' + JSON.stringify(servers));
    return servers;
  };

  async function getSessions(): Promise<Session[]> {
    console.log('getting sessions');
    return sessionData;
  }

  async function getGamePreferences(): Promise<GamePreferenceData> {
    console.log('getting game preferences');
    console.log(context?.state.token);
    let response = await fetch(`${API_BASE_URL}b/buckets/user/label/userPrefGame`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context?.state.token,
      },
    });
    if (!response.ok) {
      if (!checkAuthorization(response)) {
        throw new Error('Unauthorized fetch.');
      } else if (response.status === 404) {
        return {
          characterId: -1,
          safetyGlasses: false,
          hardHat: false,
          hearingProtection: false,
        }
      } else {
        throw new Error("Can't get game preferences");
      }
    }
    const data = await response.json();
    const rawPrefs: RawGamePreferenceData = JSON.parse(data.data);
    return {
      characterId: rawPrefs.CharacterId,
      safetyGlasses: containsAsset(SAFETY_GLASSES_ASSET, rawPrefs),
      hardHat: containsAsset(HARD_HAT_ASSET, rawPrefs),
      hearingProtection: containsAsset(HEARING_PROTECTION_ASSET, rawPrefs),
    };
  }

  async function updateGamePreferences(characterId: number, safetyGlasses: boolean, hardHat: boolean, hearingProtection: boolean) {
    console.log('updating game preferences');
    let rawPrefs: RawGamePreferenceData = {...DEFAULT_RAW_PREFERENCE_DATA};
    rawPrefs.CharacterId = characterId;
    if (safetyGlasses) {
      rawPrefs.Ppe.push({...DEFAULT_RAW_PPE_DATA, assetName: SAFETY_GLASSES_ASSET});
    }
    if (hardHat) {
      rawPrefs.Ppe.push({...DEFAULT_RAW_PPE_DATA, assetName: HARD_HAT_ASSET});
    }
    if (hearingProtection) {
      rawPrefs.Ppe.push({...DEFAULT_RAW_PPE_DATA, assetName: HEARING_PROTECTION_ASSET});
    }
    let response = await fetch(`${API_BASE_URL}b/buckets/user/label/userPrefGame`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context?.state.token,
      },
      body: JSON.stringify({
        version: 'V1.0',
        data: JSON.stringify(rawPrefs),
      }),
    });
    if (!response.ok) {
      if (!checkAuthorization(response)) {
        throw new Error('Unauthorized fetch.');
      } else {
        throw new Error("Can't update game preferences.");
      }
    }
  }
  
  function checkAuthorization(response: any) {
    if (response.status === 401) {
      context?.logout();
      return false;
    }
    return true;
  }

  function containsAsset(assetName: string, rawPrefs: RawGamePreferenceData) {
    for (let i = 0; i < rawPrefs.Ppe.length; i++) {
      const ppe = rawPrefs.Ppe[i];
      if (ppe.assetName === assetName) {
        return true;
      }
    }
    return false;
  }

  function delay(t: number) {
    return new Promise(resolve => setTimeout(resolve, t));
  }

  return {
    login,
    register,
    updatePassword,
    checkToken,
    getListenServers,
    getSessions,
    getGamePreferences,
    updateGamePreferences,
  };
}
