import React from 'react';
import { 
  AppShell, 
  Navbar, 
  Center, 
  Tooltip, 
  UnstyledButton, 
  createStyles, 
  Stack, 
  rem, 
  Title, 
  Anchor,
} from '@mantine/core';
import {
  IconHome2,
  IconUser,
  IconSettings,
  IconLogout,
  IconServer,
  IconSun,
} from '@tabler/icons-react';
import { RootContext } from '../contexts/RootContext';

const useStyles = createStyles((theme) => ({
  link: {
    width: rem(50),
    height: rem(50),
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.white,
    opacity: 0.85,
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1
      ),
    },
  },
  active: {
    opacity: 1,
    '&, &:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.15
      ),
    },
  },
}));

interface NavbarLinkProps {
  icon: React.FC<any>;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton onClick={onClick} className={cx(classes.link, { [classes.active]: active })}>
        <Icon size="1.2rem" stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const menuLinks = [
  { icon: IconHome2, label: 'Home', href: '/' },
  { icon: IconServer, label: 'Servers', href: '/servers' },
  { icon: IconUser, label: 'Account', href: '/account' },
  { icon: IconSettings, label: 'Settings', href: '/settings' },
];

export interface PageShellProps {
  title?: string;
  activeNavLink?: string;
}

export function PageShell(props: React.PropsWithChildren<PageShellProps>) {
  const context = React.useContext(RootContext);

  const links = menuLinks.map((link, index) => (
    <Anchor href={link.href}>
      <NavbarLink
        icon={link.icon}
        label={link.label}
        key={link.label}
        active={props.activeNavLink === link.label}
      />
    </Anchor>
  ));

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          width={{ base: 80 }}
          p="md"
          sx={(theme) => ({
            backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
              .background,
          })}
        >
          <Center>
            <Title order={3}>HHD</Title>
          </Center>
          <Navbar.Section grow mt={50}>
            <Stack justify="center" spacing={0}>
              {links}
            </Stack>
          </Navbar.Section>
          <Navbar.Section>
            <Stack justify="center" spacing={0}>
              <NavbarLink icon={IconSun} label="Toggle Dark Mode" onClick={() => context?.toggleDarkMode()}/>
              <NavbarLink icon={IconLogout} label="Logout" onClick={() => context?.logout()}/>
            </Stack>
          </Navbar.Section>
        </Navbar>
      }
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
    >
      <Center>
        <Title order={1} mb="lg">{props.title}</Title>
      </Center>
      {props.children}
    </AppShell>
  );
}