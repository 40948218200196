import { useState, useEffect, useContext } from 'react';
import {
  Button,
  Container,
  createStyles,
  Table,
  ScrollArea,
  rem,
  Center,
  Loader,
  Modal,
  Title,
  Stack,
  Anchor,
  Text,
  Space,
  Grid,
  Divider,
  Popover,
  TextInput,
  Box,
  ActionIcon,
  Flex
} from '@mantine/core';
import { PageShell } from "../components/PageShell";
import { Server, ServerStatus } from '../types/Server';
import { Session } from '../types/Session';
import { IconArrowsJoin, IconRefresh, IconServer, IconWalk } from '@tabler/icons-react';
import { useDisclosure, useScrollIntoView } from '@mantine/hooks';
import { useAppService } from '../services/AppService';
import { ServerBox } from '../components/ServerBox';
import { ConnectButton } from '../components/ConnectButton';
import { RootContext } from '../contexts/RootContext';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },
  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

const SCENARIO_NAME = 'cloud_fire_extinguisher_wpe';

enum GameType {
  JOIN_MULTIPLAYER,
  HOST_MULTIPLAYER,
  SINGLEPLAYER,
}

interface ServersState {
  scrolled: boolean;
  loading: boolean;
  session: Session | null;
  server: Server | null;
  serverData: Server[];
  sessionData: Session[];
  gameType: GameType;
  serverName: string;
  serverNameError: boolean;
}

const DEFAULT_SERVERS_STATE: ServersState = {
  scrolled: false,
  loading: false,
  session: null,
  server: null,
  serverData: [],
  sessionData: [],
  gameType: GameType.JOIN_MULTIPLAYER,
  serverName: '',
  serverNameError: false,
};

export function Servers() {
  const context = useContext(RootContext);
  const { classes, cx } = useStyles();
  const [state, setState] = useState<ServersState>(DEFAULT_SERVERS_STATE);
  const [canRefresh, setCanRefresh] = useState<boolean>(true);
  const [modelOpened, { open: modalOpen, close: modalClose }] = useDisclosure(false);
  const [hostOpened, { open: hostOpen, close: hostClose }] = useDisclosure(false);
  const appService = useAppService();
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 10,
    duration: 500,
  });

  useEffect(() => {
    (async function() {
      await fetchData();
    })();
  }, []);

  function openServerModal(session: Session, server: Server | null, gameType: GameType) {
    setState({
      ...state,
      session: session,
      server: server,
      gameType: gameType,
    });
    modalOpen();
  }

  async function fetchData() {
    setState({...state, loading: true});
    try {
      const serverData = await appService.getListenServers();
      const sessionData = await appService.getSessions();
      setState({
        ...state,
        loading: false,
        serverData: serverData,
        sessionData: sessionData,
      });
      setCanRefresh(false);
      setTimeout(() => setCanRefresh(true), 5000);
    } catch (e) {
      console.error(e);
    } 
  }

  function getRows() {
    if (state.serverData.length > 0) {
      const rows = state.serverData.map((server) => (
        <tr key={server.id}>
          <td><Center>{server.id}</Center></td>
          <td>{server.name}</td>
          <td>{server.address}:{server.port}</td>
          <td>
            <Center>
              <Text tt="capitalize">{ServerStatus[server.status].toLowerCase()}</Text>
            </Center>
          </td>
          <td>
            <Center>
              <ConnectButton
                label="Connect"
                icon={<IconArrowsJoin />}
                sessions={state.sessionData}
                onSessionSelect={(s: Session) => openServerModal(s, server, GameType.JOIN_MULTIPLAYER)}
              />
            </Center>
          </td>
        </tr>
      ));
      return rows;
    } else {
      return (
        <tr>
          <td colSpan={5}><Center>No servers found. Have you tried refreshing?</Center></td>
        </tr>
      );
    }
  }

  function buildSessionUrl(): string {
    let url = `${state.session?.url}?gt=${state.gameType}&game=${SCENARIO_NAME}`
    if (state.gameType === GameType.HOST_MULTIPLAYER) {
      url += `&sn=${state.serverName}`;
    }
    if (state.gameType === GameType.JOIN_MULTIPLAYER) {
      url += `&ip=${state.server?.address}&p=${state.server?.port}`;
    }
    url += `&t=${context?.state.token}`;
    return encodeURI(url);
  }

  return (
    <PageShell title="Servers" activeNavLink="Servers">
      <Container size="xl" mt={15}>
        <Grid>
          <Grid.Col span={4}>
            <ServerBox
              title="Join Multiplayer"
              description="You can join a multiplayer server by selecting from one of the available servers below."
              action={
                <ConnectButton
                  label="Join Multiplayer"
                  icon={<IconArrowsJoin />}
                  sessions={state.sessionData}
                  onClick={() => {
                    scrollIntoView({
                      alignment: 'start',
                    })
                    return false;
                  }}
                />
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ServerBox
              title="Host Multiplayer"
              description="You can host a multiplayer server by clicking the button below to get started."
              action={
                <Popover
                  width={300}
                  trapFocus
                  position="bottom"
                  withArrow
                  shadow="md"
                  opened={hostOpened}
                  onChange={(open: boolean) => {
                    if (!open && hostOpened) {
                      hostClose();
                    }
                  }}>
                  <Popover.Target>
                    <Button onClick={hostOpen} leftIcon={<IconServer />}>Host Multiplayer</Button>
                  </Popover.Target>
                  <Popover.Dropdown sx={(theme) => ({ background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white })}>
                    <Box sx={{textAlign: 'left'}}>
                      <TextInput
                        label="Server Name"
                        placeholder="Enter server name"
                        size="xs"
                        value={state.serverName}
                        onChange={(e) => {
                          setState({
                            ...state,
                            serverName: e.currentTarget.value,
                          });
                        }}
                        error={state.serverNameError && "Invalid server name"}
                      />
                      <ConnectButton
                        mt={15}
                        label="Connect"
                        icon={<IconWalk />}
                        sessions={state.sessionData}
                        onClick={() => {
                          const error = state.serverName === null || state.serverName === '';
                          setState({
                            ...state,
                            serverNameError: error,
                          });
                          return !error;
                        }}
                        onSessionSelect={(s: Session) => {
                          hostClose();
                          openServerModal(s, null, GameType.HOST_MULTIPLAYER);
                        }}
                      />
                    </Box>
                  </Popover.Dropdown>
                </Popover>
              }
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <ServerBox
              title="Single-player"
              description="If you'd prefer to play on your own or just practice before joining others, click the button below."
              action={
                <ConnectButton
                  label="Single-player"
                  icon={<IconWalk />}
                  sessions={state.sessionData}
                  onSessionSelect={(s: Session) => openServerModal(s, null, GameType.SINGLEPLAYER)}
                />
              }
            />
          </Grid.Col>
        </Grid>
        <Divider ref={targetRef} mt="lg" label="Pick a Server" labelPosition="center" />
        <Flex
          justify="flex-end"
          align="center"
          direction="row"
          my={10}
        >
          <ActionIcon onClick={fetchData} size="lg" variant="filled" disabled={state.loading || !canRefresh}>
            <IconRefresh size="1.625rem" />
          </ActionIcon>
        </Flex>
        <ScrollArea h={1000} onScrollPositionChange={({ y }) => setState({...state, scrolled: y !== 0})}>
          <Table striped highlightOnHover withBorder miw={700} horizontalSpacing="xl" verticalSpacing="md">
            <thead className={cx(classes.header, { [classes.scrolled]: state.scrolled })}>
              <tr>
                <th><Center>ID</Center></th>
                <th>Name</th>
                <th>Address</th>
                <th><Center>Status</Center></th>
                <th><Center>Join</Center></th>
              </tr>
            </thead>
            {state.loading ? (
              <tbody>
                <tr>
                  <td colSpan={5}>
                  <Center>
                    <Loader size="md" variant="bars" />
                  </Center>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>{getRows()}</tbody>
            )}
          </Table>
        </ScrollArea>
        <Modal opened={modelOpened} onClose={modalClose} withCloseButton={false} centered>
          <Center>
            <Title order={5}>You are connecting to {state.session?.name}...</Title>
          </Center>
          <Center>
            <Stack align="center">
              <Space />
              <IconServer size={50} />
              <Anchor href={buildSessionUrl()} target="_session">
                <Button onClick={modalClose}>Proceed</Button>
              </Anchor>
            </Stack>
          </Center>
        </Modal>
      </Container>
    </PageShell>
  );
}
