export enum ServerStatus {
  INACTIVE,
  ACTIVE
}

export interface Server {
  id: number;
  address: string;
  name: string;
  port: number;
  status: ServerStatus;
}