import React, { forwardRef, useState, useEffect } from "react";
import { PageShell } from "../components/PageShell";
import {
  Tabs,
  Container,
  Select,
  Text,
  Group,
  Avatar,
  Image,
  Stack,
  Center,
  Title,
  Checkbox,
  Paper,
  Button,
  Alert,
  Notification,
  Loader,
} from "@mantine/core";
import { IconAlertCircle, IconCheck, IconPhoto, IconSettings } from "@tabler/icons-react";
import { AvatarEquipment } from "../components/AvatarEquipment";
import { useAppService } from "../services/AppService";

interface AvatarData {
  id: number;
  image: string;
  label: string;
  value: string;
  description: string;
}

const avatarDatas: AvatarData[] = [
  {
    id: 256,
    image: process.env.PUBLIC_URL + 'avatars/carlos_avatar.jpg',
    label: 'Carlos',
    value: '256',
    description: 'Age 56, 38 years experience, poor physical condition with heart problems.',
  },
  {
    id: 257,
    image: process.env.PUBLIC_URL + 'avatars/charlie_avatar.jpg',
    label: 'Charlie',
    value: '257',
    description: 'Age 39, 15 years experience, mediocre physical condition.',
  },
  {
    id: 258,
    image: process.env.PUBLIC_URL + 'avatars/diego_avatar.jpg',
    label: 'Diego',
    value: '258',
    description: 'Age 23, 2 years experience, good physical condition.',
  },
  {
    id: 259,
    image: process.env.PUBLIC_URL + 'avatars/emilio_avatar.jpg',
    label: 'Emilio',
    value: '259',
    description: 'Age 28, 7 years experience, good physical condition.',
  },
  {
    id: 260,
    image: process.env.PUBLIC_URL + 'avatars/harry_avatar.jpg',
    label: 'Harry',
    value: '260',
    description: 'Age 37, 10 years experience, good physical condition.',
  },
  {
    id: 300,
    image: process.env.PUBLIC_URL + 'avatars/jake_avatar.jpg',
    label: 'Jake',
    value: '300',
    description: 'Age 29, 10 years experience, asthmatic.',
  },
  {
    id: 301,
    image: process.env.PUBLIC_URL + 'avatars/jasmine_avatar.jpg',
    label: 'Jen',
    value: '301',
    description: 'Age 22, BS in Mining Engineering with 1 year experience, good health.',
  },
  {
    id: 261,
    image: process.env.PUBLIC_URL + 'avatars/josue_avatar.jpg',
    label: 'Josue',
    value: '261',
    description: 'Age 51, 18 years experience, Diabetic.',
  },
  {
    id: 262,
    image: process.env.PUBLIC_URL + 'avatars/larry_avatar.jpg',
    label: 'Larry',
    value: '262',
    description: 'Age 38, 5 years experience, mediocre physical condition.',
  },
  {
    id: 302,
    image: process.env.PUBLIC_URL + 'avatars/louis_avatar.jpg',
    label: 'Big John',
    value: '302',
    description: 'Age 32, AA degree in dietetics with 9 years experience, fitness buff, impeccable health.',
  },
  {
    id: 263,
    image: process.env.PUBLIC_URL + 'avatars/samuel_avatar.jpg',
    label: 'Samuel',
    value: '263',
    description: 'Age 50, 16 years experience, good physical condition.',
  },
  {
    id: 303,
    image: process.env.PUBLIC_URL + 'avatars/sue_avatar.jpg',
    label: 'Sue',
    value: '303',
    description: 'Age 36, 14 years experience, U.S. Army veteran, minor health issues.',
  },
  {
    id: 264,
    image: process.env.PUBLIC_URL + 'avatars/tom_avatar.jpg',
    label: 'Tom',
    value: '264',
    description: 'Age 62, 44 years experience, Poor physical condition with shortness of breath.',
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />
        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface FormState {
  status: FormStatus;
  error?: string;
}

interface AvatarState {
  characterId: number;
  safetyGlasses: boolean;
  hardHat: boolean;
  hearingProtection: boolean;
  avatarUrl: string | null;
  avatarName: string | null;
  loading: boolean;
}

const DEFAULT_AVATAR_STATE: AvatarState = {
  characterId: -1,
  safetyGlasses: false,
  hardHat: false,
  hearingProtection: false,
  avatarUrl: null,
  avatarName: 'No avatar selected',
  loading: true,
};

export function Settings() {
  const [avatarState, setAvatarState] = useState<AvatarState>(DEFAULT_AVATAR_STATE);
  const [formState, setFormState] = useState<FormState>({status: FormStatus.READY});
  const appService = useAppService();

  useEffect(() => {
    (async function() {
      await fetchData();
    })();
  }, []);

  async function fetchData() {
    try {
      const prefs = await appService.getGamePreferences();
      console.log('prefs = ' + JSON.stringify(prefs));
      const avatarData = findAvatarData(prefs.characterId);
      if (avatarData !== null) {
        setAvatarState({
          ...avatarState,
          characterId: avatarData.id,
          avatarUrl: avatarData.image,
          avatarName: avatarData.label,
          safetyGlasses: prefs.safetyGlasses,
          hardHat: prefs.hardHat,
          hearingProtection: prefs.hearingProtection,
          loading: false,
        })
      } else {
        setAvatarState({
          ...avatarState,
          loading: false,
        });
      }
    } catch (e) {
      setAvatarState({
        ...avatarState,
        loading: false,
      });
    }
  }

  function changeAvatar(value: string) {
    console.log('change avatar = ' + value);
    const avatarData = findAvatarData(Number(value));
    if (avatarData !== null) {
      setAvatarState({
        ...avatarState,
        characterId: avatarData.id,
        avatarUrl: avatarData.image,
        avatarName: avatarData.label,
      })
    }
  }

  function findAvatarData(id: number): AvatarData | null {
    for (let i = 0; i < avatarDatas.length; i++) {
      const ad = avatarDatas[i];
      if (ad.id === id) {
        return ad;
      }
    }
    return null;
  }

  async function onSubmit(e: any) {
    e.preventDefault();
    setFormState({status: FormStatus.SUBMITTING});
    try {
      await appService.updateGamePreferences(
        avatarState.characterId,
        avatarState.safetyGlasses,
        avatarState.hardHat,
        avatarState.hearingProtection
      );
      setFormState({status: FormStatus.SUCCESS});
    } catch (e: any) {
      console.error(e);
      setFormState({status: FormStatus.ERROR, error: e.message});
    }
  };

  return (
    <PageShell title="Settings" activeNavLink="Settings">
      <Container size="md" mt={15}>
        <Tabs keepMounted={false} variant="outline"  defaultValue="avatar">
          <Tabs.List>
            <Tabs.Tab value="general" disabled={true} icon={<IconSettings size="1.0rem" />}>General Settings</Tabs.Tab>
            <Tabs.Tab value="avatar" icon={<IconPhoto size="1.0rem" />}>Customize Avatar</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="general" pt="xs">
            Coming soon...
          </Tabs.Panel>
          <Tabs.Panel value="avatar" pt="xs">
            <Paper withBorder shadow="md" p={20} mt={10} radius="md">
              {avatarState.loading ? (
                <Center>
                  <Loader size="md" variant="bars" />
                </Center>
              ) : (
                <>
                  {formState.status === FormStatus.SUCCESS && (
                    <Notification mb="lg" withCloseButton={false} icon={<IconCheck size="1.2rem" />} color="green" title="Avatar updated!">
                      Your avatar has been successfully updated.
                    </Notification>
                  )}
                  {formState.status === FormStatus.ERROR && (
                    <Alert icon={<IconAlertCircle size="1rem" />} variant="outline" title="Avatar update failed" color="red" mb="lg">
                      Your avatar could not be updated. Please try again later.
                    </Alert>
                  )}
                  <form onSubmit={onSubmit}>
                    <Group spacing="xs" align="flex-start" position="center" grow={true}>
                      <Stack>
                        <Center>
                          <Title order={5}>Avatar Options</Title>
                        </Center>
                        <Select
                          label="Choose your avatar"
                          placeholder="Pick one"
                          itemComponent={SelectItem}
                          data={avatarDatas}
                          maxDropdownHeight={400}
                          nothingFound="No avatars found"
                          value={'' + avatarState.characterId}
                          onChange={changeAvatar}
                        />
                        <Checkbox
                          mt={10}
                          label="Safety Glasses"
                          description="Clear safety glasses with anti-fog lenses."
                          checked={avatarState.safetyGlasses}
                          onChange={(event) => setAvatarState({...avatarState, safetyGlasses: event.currentTarget.checked})}
                        />
                        <Checkbox
                          mt={10}
                          label="Hard Hat"
                          description="Full brim OSHA approved hard hat with lamp bracket."
                          checked={avatarState.hardHat}
                          onChange={(event) => setAvatarState({...avatarState, hardHat: event.currentTarget.checked})}
                        />
                        <Checkbox
                          mt={10}
                          label="Hearing Protection"
                          description="Hearing Protection, Earmuffs"
                          checked={avatarState.hearingProtection}
                          onChange={(event) => setAvatarState({...avatarState, hearingProtection: event.currentTarget.checked})}
                        />
                      </Stack>
                      <Stack>
                        <Center>
                          <Title order={5}>Avatar Preview</Title>
                        </Center>
                        <Group align="flex-start" position="right">
                          <Image
                            width={300}
                            height={300}
                            src={avatarState.avatarUrl}
                            alt="Your avatar"
                            withPlaceholder
                            radius="md"
                            caption={avatarState.avatarName}
                            sx={{borderWidth: 1, borderColor: '#ff0000'}}
                          />
                          <Stack>
                            <AvatarEquipment
                              active={avatarState.safetyGlasses}
                              image={process.env.PUBLIC_URL + '/avatars/glasses_ppe.jpg'}
                              onClick={() => setAvatarState({...avatarState, safetyGlasses: !avatarState.safetyGlasses})}
                            />
                            <AvatarEquipment
                              active={avatarState.hardHat}
                              image={process.env.PUBLIC_URL + '/avatars/helmet_ppe.jpg'}
                              onClick={() => setAvatarState({...avatarState, hardHat: !avatarState.hardHat})}
                            />
                            <AvatarEquipment
                              active={avatarState.hearingProtection}
                              image={process.env.PUBLIC_URL + '/avatars/hearing_ppe.jpg'}
                              onClick={() => setAvatarState({...avatarState, hearingProtection: !avatarState.hearingProtection})}
                            />
                          </Stack>
                        </Group>
                      </Stack>
                    </Group>
                    <Center>
                      <Button
                        type="submit"
                        leftIcon={<IconPhoto size="1rem" />}
                        disabled={avatarState.characterId === -1}
                        loading={formState.status === FormStatus.SUBMITTING}
                        mt="xl"
                      >
                        {formState.status === FormStatus.SUBMITTING ? 'Submitting...' : 'Save'}
                      </Button>
                    </Center>
                  </form>
                </>
              )}
            </Paper>
          </Tabs.Panel>
        </Tabs>
      </Container>
    </PageShell>
  );
}