import { MantineProvider } from '@mantine/core';
import {
  Routes,
  Route,
  Link as RouterLink,
  Navigate,
} from "react-router-dom";
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { forwardRef, useReducer, useEffect, useState } from 'react';
import { Register } from './pages/Register';
import { DefaultState, RootContext, RootContextType, StateType } from './contexts/RootContext';
import { User } from './types/User';
import { Servers } from './pages/Servers';
import { Loading } from './pages/Loading';
import { useAppService } from './services/AppService';
import { Settings } from './pages/Settings';
import { Account } from './pages/Account';

const LOCAL_STORAGE_AUTH_KEY = 'auth';
const LOCAL_STORAGE_THEME_KEY = 'theme';

const LinkBehavior = forwardRef((props: any, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

enum StateAction {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  INIT = "INIT",
  TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE",
}

interface StateActionType {
  type: StateAction;
  payload: any | null;
}

interface StoredAuthDataType {
  user: User | null;
  token: string | null;
}

interface StoredThemeDataType {
  darkMode: boolean | null;
}

export default function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer(rootContextReducer, DefaultState);
  const appService = useAppService();

  function rootContextReducer(state: StateType, action: StateActionType): StateType {
    const { type, payload } = action;
    switch (type) {
      case StateAction.LOGIN:
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify({user: payload.user, token: payload.token}));
        return {
          ...state,
          authenticated: true,
          user: payload?.user,
          token: payload?.token,
        };
      case StateAction.LOGOUT:
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        return {
          ...state,
          authenticated: false,
        };
      case StateAction.INIT:
        return {
          ...state,
          authenticated: payload?.user !== null && payload?.token !== null,
          user: payload?.user,
          token: payload?.token,
          darkMode: payload?.darkMode !== null ? payload?.darkMode : state.darkMode,
        };
      case StateAction.TOGGLE_DARK_MODE:
        console.log('toggling dark mode');
        const val = !state.darkMode;
        localStorage.setItem(LOCAL_STORAGE_THEME_KEY, JSON.stringify({darkMode: val}));
        return {
          ...state,
          darkMode: val,
        };
      default:
        return state;
    }
  }

  const rootContext = {
    state: state,
    login: (user: User, token: string) => dispatch({type: StateAction.LOGIN, payload: {user: user, token: token}}),
    logout: () => dispatch({type: StateAction.LOGOUT, payload: null}),
    init: (user: User | null, token: string | null, darkMode: boolean | null) => 
      dispatch({type: StateAction.INIT, payload: {user: user, token: token, darkMode: darkMode}}),
    toggleDarkMode: () => dispatch({type: StateAction.TOGGLE_DARK_MODE, payload: null})
  } as RootContextType;

  useEffect(() => {
    async function loadAuthData(): Promise<StoredAuthDataType> {
      const data = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
      if (data) {
        try {
          const { user, token } = JSON.parse(data);
          let check = token ? await appService.checkToken(token) : false;
          if (!check) {
            throw new Error('Invalid token');
          }
          return {user, token};
        } catch (e) {
          localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        }
      }
      return {user: null, token: null};
    }

    async function loadThemeData(): Promise<StoredThemeDataType> {
      const data = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
      if (data) {
        try {
          return JSON.parse(data);
        } catch (e) {
          localStorage.removeItem(LOCAL_STORAGE_THEME_KEY);
        }
      }
      return {darkMode: null};
    }
    
    (async function () {
      const { user, token } = await loadAuthData();
      const { darkMode } = await loadThemeData();
      rootContext.init(user, token, darkMode);
      setTimeout(() => setLoading(false), 750);
    })();
  }, []);

  return (
    <RootContext.Provider value={rootContext}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: state.darkMode ? 'dark' : 'light',
          components: {
            Anchor: {
              defaultProps: {
                component: LinkBehavior,
              }
            }
          }
        }}>
        <Routes>
          {loading ? (
            <>
              <Route path="*" element={<Loading />} />
            </>
          ) : (
            !state.authenticated ? (
              <>
                <Route path="/" element={<Login />} />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/register" element={<Register />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/servers" element={<Servers />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/account" element={<Account />} />
              </>
            )
          )}
        </Routes>
      </MantineProvider>
    </RootContext.Provider>
  );
}