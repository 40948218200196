import React from 'react';
import { Title, Paper, Text, Box } from '@mantine/core';

type ServerButtonProps = {
  title: string;
  description: string;
  action: JSX.Element;
};

export function ServerBox(props: React.PropsWithChildren<ServerButtonProps>) {
  return (
    <Paper shadow="xs" p="md" withBorder mih={200}>
      <Box sx={{textAlign: 'center'}}>
        <Title order={3} mb={15}>{props.title}</Title>
        <Text mb={25}>{props.description}</Text>
        {props.action}
      </Box>
    </Paper>
  );
}
