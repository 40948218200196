import React, { useState } from "react";
import { PageShell } from "../components/PageShell";
import {
  Tabs,
  Container,
  Paper,
  Alert,
  PasswordInput,
  Notification,
  Button,
  Center
} from "@mantine/core";
import { IconLock, IconAlertCircle, IconCheck } from "@tabler/icons-react";
import { useForm, hasLength } from "@mantine/form";
import { useAppService } from "../services/AppService";

enum FormStatus {
  READY,
  SUBMITTING,
  ERROR,
  SUCCESS,
}

interface PasswordFormValues {
  password: string;
  confirmPassword: string;
}

interface PasswordFormState {
  status: FormStatus;
  error?: string;
}

export function Account() {
  const appService = useAppService();
  const [passwordFormState, setPasswordFormState] = useState<PasswordFormState>({status: FormStatus.READY});
  const passwordForm = useForm<PasswordFormValues>({
    validateInputOnBlur: true,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: hasLength({ min: 6 }, 'Password should contain at least 6 characters'),
      confirmPassword: (value, values) => value !== values.password ? 'Passwords do not match' : null,
    },
  });

  async function onSubmit(values: any) {
    console.log(values);
    setPasswordFormState({status: FormStatus.SUBMITTING});
    try {
      await appService.updatePassword(values.password);
      setPasswordFormState({status: FormStatus.SUCCESS});
    } catch (e: any) {
      console.error(e);
      setPasswordFormState({status: FormStatus.ERROR, error: e.message});
    }
  };

  return (
    <PageShell title="Account" activeNavLink="Account">
      <Container size="md" mt={15}>
        <Tabs variant="outline"  defaultValue="password">
          <Tabs.List>
            <Tabs.Tab value="password" icon={<IconLock size="1.0rem" />}>Change Password</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="password" pt="xs">
            {passwordFormState.status === FormStatus.SUCCESS ? (
              <Notification mt="xl" withCloseButton={false} icon={<IconCheck size="1.2rem" />} color="green" title="Password updated!">
                Your password has been successfully updated.
              </Notification>
            ) : (
              <Paper withBorder shadow="md" p={20} mt={10} radius="md">
                {passwordFormState.status === FormStatus.ERROR && (
                  <Alert icon={<IconAlertCircle size="1rem" />} variant="outline" title="Change password failed" color="red" mb="1.5rem">
                    Your password could not be changed. Please try again later.
                  </Alert>
                )}
                <form onSubmit={passwordForm.onSubmit((values) => onSubmit(values))}>
                  <PasswordInput
                    withAsterisk
                    label="Password"
                    placeholder="Enter your password"
                    mt="md"
                    {...passwordForm.getInputProps('password')}
                  />
                  <PasswordInput
                    withAsterisk
                    label="Confirm Password"
                    placeholder="Enter your password"
                    mt="md"
                    {...passwordForm.getInputProps('confirmPassword')}
                  />
                  <Center>
                    <Button
                      type="submit"
                      leftIcon={<IconLock size="1rem" />}
                      disabled={!passwordForm.isValid()}
                      loading={passwordFormState.status === FormStatus.SUBMITTING}
                      mt="xl"
                    >
                      {passwordFormState.status === FormStatus.SUBMITTING ? 'Submitting...' : 'Save'}
                    </Button>
                  </Center>
                </form>
              </Paper>
            )}
          </Tabs.Panel>
        </Tabs>
      </Container>
    </PageShell>
  );
}