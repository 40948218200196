export enum SessionStatus {
  INACTIVE,
  ACTIVE
}

export interface Session {
  id: number;
  name: string;
  status: SessionStatus;
  url: string;
}