import { useState, useContext, useEffect } from 'react';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Alert,
} from '@mantine/core';
import { useForm, isNotEmpty, hasLength } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons-react';
import { useAppService } from '../services/AppService';
import './Login.css';
import { RootContext } from '../contexts/RootContext';
import { IconLogin } from '@tabler/icons-react';

const LOCAL_STORAGE_LOGIN_KEY = 'login';

enum LoginStatus {
  READY,
  SUBMITTING,
  ERROR,
}

interface FormValues {
  username: string;
  password: string;
  rememberMe: boolean;
}

export function Login() {
  const context = useContext(RootContext);
  const appService = useAppService();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>(LoginStatus.READY);
  const form = useForm<FormValues>({
    validateInputOnBlur: true,
    initialValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
    validate: {
      username: isNotEmpty('Username is required'),
      password: isNotEmpty('Password is required'),
    },
  });

  useEffect(() => {
    const username = localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY);
    if (username) {
      form.setValues({username: username, rememberMe: true});
    }
  }, []);

  async function updateStoredLogin(rememberMe: boolean, username: string) {
    if (rememberMe) {
      localStorage.setItem(LOCAL_STORAGE_LOGIN_KEY, username);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_LOGIN_KEY);
    }
  }

  async function onSubmit(values: any) {
    setLoginStatus(LoginStatus.SUBMITTING);
    try {
      let response = await appService.login(values.username, values.password);
      updateStoredLogin(values.rememberMe, values.username);
      context?.login(response.user, response.token);
    } catch (e: any) {
      console.error(e);
      setLoginStatus(LoginStatus.ERROR);
    }
  };

  return (
    <Container size={620} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Welcome back!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Don't have an account yet?{' '}
        <Anchor href="/register" size="sm">
          Create account
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {loginStatus === LoginStatus.ERROR && (
          <Alert icon={<IconAlertCircle size="1rem" />} variant="outline" title="Sign in failed" color="red" mb="1.5rem">
            The username or password specified could not be authenticated. Please check your credentials and try again.
          </Alert>
        )}
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
          <TextInput
            withAsterisk
            label="Username"
            placeholder="Enter username"
            {...form.getInputProps('username')}
          />
          <PasswordInput
            withAsterisk
            label="Password"
            placeholder="Enter your password"
            mt="md"
            {...form.getInputProps('password')}
          />
          <Group position="apart" mt="lg">
            <Checkbox label="Remember me" {...form.getInputProps('rememberMe', { type: 'checkbox' })} />
            <Anchor component="button" size="sm">
              Forgot password?
            </Anchor>
          </Group>
          <Button type="submit" leftIcon={<IconLogin size="1rem" />} disabled={!form.isValid()} loading={loginStatus === LoginStatus.SUBMITTING} fullWidth mt="xl">
            {loginStatus === LoginStatus.SUBMITTING ? 'Submitting...' : 'Sign in'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}